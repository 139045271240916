import {createSlice} from "@reduxjs/toolkit";

export const selectIsTourOpen = state => state.CourseTour.isOpen;


const initialState = {
  isOpen: false,
}

const courseTourSlice = createSlice({
  name: 'courseTour',
  initialState,
  reducers: {
    openCourseTour: state => {
      state.isOpen = true;

      return state;
    },
    closeCourseTour: state => {
      state.isOpen = false;

      return state;
    }

  }
})

export const {
  openCourseTour,
  closeCourseTour
} = courseTourSlice.actions

export default courseTourSlice.reducer