import React, {useState} from 'react';
import style from './styles/account.module.scss';
import {Link, Redirect, Route, Switch, useLocation, useRouteMatch} from "react-router-dom";
import SITE_ROUTES from "../shared/config/routes";
import AccountProfile from "./AccountProfile";
import AccountPassword from "./AccountPassword";
import {useSelector} from "react-redux";
import {selectUser} from "../../redux/slices/authSlice";
import {FiEdit2} from 'react-icons/fi';
import {usePopper} from "react-popper";

const ProfileLink = ({to, children}) => {

  const {pathname} = useLocation();

  return (
    <li className={to === pathname && "active"}><Link to={to}>{children}</Link></li>
  )
}

function AccountApp(props) {

  const user = useSelector(selectUser);

  return (
    <>
      <div className={style.banner}>
        <div className={style.bannerInner}>
          <figure className="image is-96x96">
            <img className="is-rounded" src={user.picture_url} alt=""/>

            {/*<button ref={setReferenceElement} className={style.editButton}>*/}
            {/*  <FiEdit2/>*/}
            {/*</button>*/}
            {/*<div ref={setPopperElement} style={popperStyle.popper} {...attributes.popper}>*/}
            {/*  Upload*/}
            {/*  <div ref={setArrowElement} style={popperStyle.arrow} />*/}
            {/*</div>*/}
          </figure>
          <div className="mt-3">
            <h2 className="is-size-4">My Account</h2>
          </div>
        </div>

      </div>

      <div className={style.content}>
        <div className={style.outer}>
          <div className={style.inner}>
            <div className={style.nav}>
              <ul>
                <ProfileLink to={SITE_ROUTES.ACCOUNT_PROFILE}>Profile</ProfileLink>
                <ProfileLink to={SITE_ROUTES.ACCOUNT_PASSWORD}>Password</ProfileLink>
              </ul>
            </div>
            <div className={style.setting}>
              <Switch>
                <Route exact path={SITE_ROUTES.ACCOUNT_PROFILE} component={AccountProfile}/>
                <Route exact path={SITE_ROUTES.ACCOUNT_PASSWORD} component={AccountPassword}/>
                <Route exact path={SITE_ROUTES.ACCOUNT} render={() => <Redirect to={SITE_ROUTES.ACCOUNT_PROFILE}/>}/>
              </Switch>

            </div>

          </div>
        </div>
      </div>
    </>
  );
}

export default AccountApp;