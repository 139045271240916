import {createSlice} from "@reduxjs/toolkit";

export const selectShowVideoOverlay = state => state.VideoOverlay.showOverlay;
export const selectVideoOverlayVideoId = state => state.VideoOverlay.videoId;

const initialState = {
  showOverlay: false,
  videoId: null,
}

const videoOverlaySlice = createSlice({
  initialState,
  name: 'videoOverlay',
  reducers: {
    showVideoOverlay: (state, {payload}) => {
      state.showOverlay = true;
      state.videoId = payload.videoId;

      return state;
    },

    hideVideoOverlay: state => {
      state = initialState;

      return state;
    }
  }
})

export const {
  hideVideoOverlay,
  showVideoOverlay
} = videoOverlaySlice.actions;

export default videoOverlaySlice.reducer;