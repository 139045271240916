import {Redirect} from "react-router-dom";
import React from "react";

export const AUTH_ROUTES = {
  INDEX: '/auth',
  LOGIN: '/auth/login',
  REGISTER: '/auth/register',
  NEW_PASSWORD: '/auth/new',
  RESET_PASSWORD: '/auth/reset/:resetToken',
}

const authConfig = {
  routes: [
    {
      path: AUTH_ROUTES.INDEX,
      exact: true,
      component: () => <Redirect to={AUTH_ROUTES.LOGIN} />
    },
    {
      path: AUTH_ROUTES.REGISTER,
      exact: true,
      component:  React.lazy(() => import('./pages/RegistrationPage/RegistrationPage')),
    },
    {
      path: AUTH_ROUTES.LOGIN,
      exact: true,
      component:  React.lazy(() => import('./pages/LoginPage/LoginPage')),
    },
    {
      path: AUTH_ROUTES.NEW_PASSWORD,
      exact: true,
      component: React.lazy(() => import('./pages/RequestNewPasswordPage/RequestNewPasswordPage')),
    },
    {
      path: AUTH_ROUTES.RESET_PASSWORD,
      exact: true,
      component: React.lazy(() => import('./pages/ResetPasswordPage/ResetPasswordPage')),
    },
  ]
}

export default authConfig;