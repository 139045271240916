import {createSlice} from "@reduxjs/toolkit";

export const selectMobileCourseNavOpen = state => state.CourseNav.mobileOpen;

const courseNavSlice = createSlice({
  name: 'courseNavSlice',
  initialState: {
    mobileOpen: false,
  },
  reducers: {
    openMobileNav: (state, action) => {
      state.mobileOpen = true;

      return state;
    },
    closeMobileNav: (state, action) => {
      state.mobileOpen = false;

      return state;
    }
  }
})

export const {
  openMobileNav,
  closeMobileNav
} = courseNavSlice.actions

export default courseNavSlice.reducer