import {createSlice} from "@reduxjs/toolkit";

// selectors

export const selectMobileNavOpen = state => state.Core.mobileNavOpen;
export const selectIsTourRunning = state => state.Core.isTourRunning;
export const selectShowEnrollPrompt = state => state.Core.showEnrollPrompt;

const initialState = {
  mobileNavOpen: false,
  isTourRunning: false,
  showEnrollPrompt: false,
}

const coreSlice = createSlice({
  name: 'core',
  initialState,
  reducers: {
    setMobileNavOpen: (state, {payload}) => {
      state.mobileNavOpen = payload;

      return state;
    },

    setTourRunning: (state, {payload}) => {
      state.isTourRunning = payload;

      return state;
    }
  }
})

export const {setMobileNavOpen} = coreSlice.actions;

export default coreSlice.reducer