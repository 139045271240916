import React, {Component} from 'react';
import BasicRequest from "../../../api";
import apiRoutes from "../../../api/apiRoutes";
import {connect} from "react-redux";
import {setSiteInfo} from "../../../redux/slices/siteSlice";
import SplashScreen from "../../../Core/Components/SplashScreen/SplashScreen";
import {setSiteIdentifier} from "../../../Core/Utils/AuthUtils";
import {generatePath} from "react-router-dom";

/**
 * This class sends a request to the
 */
class SiteKeyProvider extends Component {

  state = {
    siteResolved: false,
  }

  timer = null;

  componentDidMount() {
    if (this.checkPreviewUrl()) {
      // We are on the preview domain
      const urlSearchParams = new URLSearchParams(window.location.search);
      const urlParams = Object.fromEntries(urlSearchParams.entries());

      const siteKey = urlParams.site || localStorage.getItem('siteIdentifier');

      if (siteKey) {
        // this.props.dispatch(setSiteInfo())

        BasicRequest.get(generatePath(apiRoutes.VERIFY_SITE_KEY, {siteKey: siteKey}))
           .then(({data: {data: site}}) => {
             localStorage.setItem('siteIdentifier', siteKey);

             if (site) {
               const {identifier} = site;
               setSiteIdentifier(identifier);

               this.props.dispatch(setSiteInfo(site));

               this.setState({
                 siteResolved: true
               })
             }
           })

        return;
      }

    }


    this.checkSiteKey();

    this.timer = setInterval(this.checkSiteKey, 10 * 1000)

  }

  checkPreviewUrl = () => {
    return window.location.hostname === process.env.REACT_APP_PREVIEW_DOMAIN;
  }

  checkSiteKey = () => {
    BasicRequest.get(apiRoutes.RESOLVE_SITE_KEY, {
      headers: {
        'X-Domain': window.location.hostname,
      }
    })
       .then(({data: {data: site}}) => {


         if (site) {
           const {identifier} = site;
           setSiteIdentifier(identifier);

           this.props.dispatch(setSiteInfo(site));

           this.setState({
             siteResolved: true
           })
         }

         clearInterval(this.timer);
       })
       .catch(err => {

       })
  }


  render() {
    const {siteResolved} = this.state;

    return (
       <>
         {
           siteResolved ? this.props.children : <SplashScreen/>
         }
       </>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  dispatch
})
export default connect(null, mapDispatchToProps)(SiteKeyProvider);