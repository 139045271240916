import React from 'react';
import style from './splashScreen.module.scss';
import {ClipLoader} from "react-spinners";

function SplashScreen(props) {
  return (
    <div className={style.wrapper}>
      <div className={style.inner}>
        <div>
          <ClipLoader size={50}/>
        </div>
        <div className={style.loadingText}>
          Loading...
        </div>

      </div>
    </div>
  );
}

export default SplashScreen;