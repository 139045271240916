import React from 'react';
import LayoutHeader from "./LayoutHeader";
import {Helmet} from "react-helmet";
import {useSelector} from "react-redux";
import {selectSiteName} from "../redux/slices/siteSlice";

function Layout(props) {

  const siteName = useSelector(selectSiteName);

  return (
    <>
      <Helmet>
        <title>{siteName}</title>
      </Helmet>
      <div id="course-container">
        <LayoutHeader/>
        <div className="site-wrapper">
        {props.children}
        </div>
      </div>
    </>
  );
}

export default Layout;