import {createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import apiRoutes from "../../api/apiRoutes";
import {generatePath} from "react-router-dom";

export const selectEnrollmentCourse = state => state.Enrollment.course;
export const selectEnrollmentCourseSlug = state => state.Enrollment.courseSlug;
export const selectEnrollmentSetupIntent = state => state.Enrollment.setupIntent;
export const selectEnrollmentIsPaying = state => state.Enrollment.isPaying;
export const selectEnrollmentPaymentComplete = state => state.Enrollment.isPaymentComplete;
export const selectEnrollmentCoupon = state => state.Enrollment.coupon;
export const selectEnrollmentCouponInvalid = state => state.Enrollment.couponInvalid;

export const getSetupIntent = () => dispatch => {
  axios.get(apiRoutes.ENROLL_SETUP_INTENT)
     .then(({data}) => {
       dispatch(setSetupIntent(data.data.intent));
     })
     .catch(() => {

     })
     .finally(() => {

     })
}

export const completeEnrollmentPayment = ({payment_method, courseId, price_id, couponCode}) => dispatch => {
  dispatch(setIsPaying(true))
  axios.post(generatePath(apiRoutes.ENROLL_COURSE, {courseId}), {
    payment_method: payment_method,
    price_id,
    coupon_code: couponCode || null,
  })
     .then(({data}) => {
       console.log(data);
     })
     .catch(err => {
       console.log(err.response)

       dispatch(setPaymentError(err.response));
     })
     .finally(() => {
       dispatch(setSetupIntent(null));
       dispatch(setIsPaying(false));
       dispatch(setPaymentComplete(true));
     })
}
export const checkCouponCode = (courseId, code) => dispatch => {
  axios.post(apiRoutes.CHECK_COUPON, {
    coupon_code: code,
    course_id: courseId,
  })
     .then(({data}) => {
       if (data.is_valid) {
         dispatch(setCoupon(data.coupon));
       } else {
         dispatch(setCouponInvalid());
       }
     })
}

export const getEnrollmentCourse = ({courseId}) => dispatch => {
  axios.get(generatePath(apiRoutes.ENROLL_COURSE, {courseId}))
     .then(({data}) => {
       dispatch(setEnrollmentCourse(data.data));
     })
     .catch(() => {

     })
     .finally(() => {

     })
}

const initialState = {
  course: null,
  courseSlug: null,
  setupIntent: null,
  isPaying: false,
  isPaymentComplete: false,
  paymentError: null,
  coupon: null,
  checkingCoupon: false,
  couponInvalid: false,
}

const enrollmentSlice = createSlice({
  name: 'enrollment',
  initialState,
  reducers: {
    setEnrollmentCourse: (state, {payload}) => {
      state.course = payload;
      state.courseSlug = payload.slug;

      return state;
    },
    setSetupIntent: (state, {payload}) => {
      state.setupIntent = payload;
      return state;
    },
    setIsPaying: (state, {payload}) => {
      state.isPaying = payload;

      return state;
    },
    setPaymentComplete: (state, {payload}) => {
      state.isPaymentComplete = payload;

      return state;
    },
    setPaymentError: (state, {payload}) => {
      state.paymentError = payload;

      return state;
    },

    resetEnrollment: (state) => {
      return initialState;
    },

    setCoupon: (state, {payload}) => {
      state.coupon = payload;
      state.couponInvalid = false;

      return state;
    },
    clearCoupon: (state) => {
      state.coupon = null;
      state.couponInvalid = false;

      return state;
    },
    setCouponInvalid: (state) => {
      state.couponInvalid = true;

      return state;
    }
  },
})

const {setEnrollmentCourse, setSetupIntent, setIsPaying, setPaymentComplete, setPaymentError, setCoupon, setCouponInvalid} = enrollmentSlice.actions;

export const {resetEnrollment, clearCoupon: clearEnrollmentCoupon} = enrollmentSlice.actions;

export default enrollmentSlice.reducer;