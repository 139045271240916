import React, {Component} from 'react';
import {connect} from 'react-redux';
import {checkUser} from "../../redux/slices/authSlice";
import {setAccessToken} from "../../Core/Utils/AuthUtils";
import SplashScreen from "../../Core/Components/SplashScreen/SplashScreen";
import qs from 'qs';

class UserInfoUpdater extends Component {

  interval = null;

  state = {
    waitForAuth: true,
  }

  componentDidMount() {
    this.updateUser();
    this.interval = setInterval(this.updateUser, 1000 * 60 * 5); // every 5 minutes
  }

  updateUser = () => {
    const {dispatch} = this.props;

    const urlSearchParams = new URLSearchParams(window.location.search);
    const urlParams = Object.fromEntries(urlSearchParams.entries());

    let accessToken;

    if(urlParams.token){
      accessToken = urlParams.token;
    }
    else {
      accessToken = localStorage.getItem('accessToken');
    }

    if (accessToken) {
      setAccessToken(accessToken);
    }

    if (accessToken) {
      dispatch(checkUser({
        final: () => {
          this.setState({
            waitForAuth: false,
          })
        },
        onFailure: () => {
          clearInterval(this.interval)
        }
      }))

    } else {
      this.setState({
        waitForAuth: false,
      })
    }
  }

  render() {
    const {waitForAuth} = this.state;

    return waitForAuth ? <SplashScreen/> : this.props.children;
  }
}

export default connect(null, dispatch => ({dispatch}))(UserInfoUpdater);
