import axios from "axios";
import apiRoutes from "../../api/apiRoutes";
import _ from 'lodash';
import {generatePath} from "react-router-dom";
import {selectCompletedLessons, setLessonComplete, setLessonIncomplete} from "./authSlice";

const {createSlice} = require("@reduxjs/toolkit");

export const selectCoursesLoading = state => state.Courses.isLoadingCourses;
export const selectAllCourses = state => state.Courses.list;
export const selectCourseBySlug = courseSlug => state => _.find(state.Courses.list, course => course.slug === courseSlug);
export const selectCourseSettings = courseSlug => state => _.find(state.Courses.list, course => course.slug === courseSlug)?.settings;

export const selectCourseLoaded = (courseSlug) => state => {
  return !!_.find(state.Courses.loaded, _courseId => _courseId === courseSlug);
}
export const selectCourseFailed = (courseSlug) => state => {
  return !!_.find(state.Courses.failed, _courseId => _courseId === courseSlug);
}
export const selectIsLessonCompleted = lessonId => state => selectCompletedLessons(state).includes(lessonId);
export const selectNumberOfLessonsCompletedInChapter = ({courseId, chapterId}) => state => {
  const chapter = selectChapter(courseId, chapterId)(state);

  let numberCompleted = 0;

  chapter.lessons.forEach(lesson => {
    if(selectIsLessonCompleted(lesson.id)(state)){
      numberCompleted += 1;
    }
  })

  return numberCompleted;
}

export const selectChapter = (courseSlug, chapterSlug) => state => {
  const course = _.find(state.Courses.list, course => course.slug === courseSlug)

  if (!course) {
    return false;
  }

  const chapter = _.find(course.chapters, chapter => chapter.slug === chapterSlug)
  return chapter || false;
}
export const selectNextChapter = (courseSlug, chapterSlug) => state => {
  const course = selectCourseBySlug(courseSlug)(state);

  if (!course) {
    return false;
  }

  const chapterIndex = _.findIndex(course.chapters, _chapter => _chapter.slug === chapterSlug);

  if (chapterIndex === -1 || course.chapters.length <= (chapterIndex + 1)) {
    return false;
  }

  return course.chapters[chapterIndex + 1];
}
export const selectLesson = (courseSlug, chapterSlug, lessonSlug) => state => {
  const chapter = selectChapter(courseSlug, chapterSlug)(state);

  if (!chapter) {
    return false;
  }

  const lesson = _.find(chapter.lessons, lesson => lesson.slug === lessonSlug);

  return lesson || false;
}
export const selectNextLesson = (courseSlug, chapterSlug, lessonSlug) => state => {
  const chapter = selectChapter(courseSlug, chapterSlug)(state);

  if (!chapter) {
    return false;
  }

  const lessonIndex = _.findIndex(chapter.lessons, _lesson => _lesson.slug === lessonSlug);

  if (lessonIndex === -1 || chapter.lessons.length <= (lessonIndex + 1)) {
    return false;
  }

  return chapter.lessons[lessonIndex + 1];
}

export const selectIsEnrolledInCourse = (courseId) => state => state.Auth.user.enrolled_courses?.includes(courseId);

// Thunk Actions
export const loadCourseList = () => dispatch => {
  dispatch(setCoursesLoading(true))
  axios.get(apiRoutes.COURSE_GET_LIST)
    .then(({data}) => {
      dispatch(updateCourseList(data.data));
    })
    .catch(err => {
      console.log(err);
    })
    .finally(() => {
      dispatch(setCoursesLoading(false))
    })
}
export const loadCourseCurriculum = ({courseId}) => dispatch => {
  dispatch(setCoursesLoading(true));

  axios.get(generatePath(apiRoutes.COURSE_GET_CURRICULUM, {courseId}))
    .then(({data}) => {
      dispatch(setCourseCurriculum(data.data));
    })
    .catch(({data}) => {
      dispatch(addFailedCourse({courseId}));
    })
    .finally(() => {
      dispatch(setCoursesLoading(false));
    })
}

export const markLessonComplete = ({courseId, chapterId, lessonSlug: lessonId, id,}) => dispatch => {
  dispatch(setLessonComplete({lessonId: id}));

  axios.put(generatePath(apiRoutes.LESSON_COMPLETE, {courseId, chapterId, lessonId}))
    .then(() => {
    })
    .catch(() => {
      dispatch(setLessonIncomplete({lessonId: id}));
    })
}
export const markLessonIncomplete = ({courseId, chapterId, lessonSlug: lessonId, id,}) => dispatch => {
  dispatch(setLessonIncomplete({lessonId: id}));

  axios.put(generatePath(apiRoutes.LESSON_INCOMPLETE, {courseId, chapterId, lessonId}))
    .then(() => {
    })
    .catch(() => {
      dispatch(setLessonComplete({lessonId: id}));
    })
}

const initialState = {
  list: [],
  loaded: [],
  failed: [],
  isLoadingCourses: false,
}

const courseSlice = createSlice({
  name: 'courses',
  initialState,
  reducers: {
    updateCourseList: (state, {payload}) => {
      state.list = _.merge(state.list, payload)
      return state;
    },

    clearCourseList: (state) => {
      state.list = [];
    },

    setCoursesLoading: (state, {payload}) => {
      state.isLoadingCourses = payload;

      return state;
    },

    setCourseCurriculum: (state, {payload: newCourseData}) => {
      const existingCourse = _.find(state.list, course => course.id === newCourseData.id);
      // If the course doesn't exist in the store, add it otherwise, update it
      if (!existingCourse) {
        state.list.push(newCourseData);
      } else {
        // const index = _.findIndex(state.list, course => course.id === newCourseData.id);

        _.merge(existingCourse, newCourseData)
      }

      // remove it from failed courses if exists
      state.failed = _.filter(state.failed, courseSlug => courseSlug !== newCourseData.slug)

      // add it to loaded courses
      state.loaded = _.union(state.loaded, [newCourseData.slug])
      return state;
    },

    addFailedCourse: (state, {payload: {courseId}}) => {
      state.failed = _.union(state.failed, [courseId]);

      return state;
    }
  }
})

export const {
  updateCourseList,
  clearCourseList,
  setCoursesLoading,
  addFailedCourse,
  setCourseCurriculum
} = courseSlice.actions;

export default courseSlice.reducer;

