import {createSlice} from "@reduxjs/toolkit";
import _ from 'lodash';
import axios from "axios";
import apiRoutes from "../../api/apiRoutes";
import {generatePath} from "react-router-dom";

export const selectIsCommentsLoading = state => state.Comments.isLoading;
export const selectIsCommentsSubmitting = state => state.Comments.submitting;
export const selectCommentsSorting = state => state.Comments.sorting;
export const selectTotalComments = state => state.Comments.total;
export const selectCommentsLength = state => state.Comments.list.length;
export const selectCommentsPage = state => state.Comments.list.page;

export const selectCommentsByLessonId = lessonId => state => _.filter(state.Comments.list, comment => comment.lesson_id === lessonId);

// Actions

export const loadComments = ({courseId, chapterId, lessonId}) => (dispatch, getState) => {
  dispatch(setCommentsLoading(true));
  const state = getState();
  const sorting = selectCommentsSorting(state);

  dispatch(setCommentsPage(1));

  axios.get(generatePath(apiRoutes.LESSON_COMMENTS, {courseId, chapterId, lessonId, sorting, page: 1}))
    .then(({data}) => {
      dispatch(setComments(data.data));
      dispatch(setTotalComments(data.meta.total))
    })
    .catch(err => {

    })
    .finally(() => {
      dispatch(setCommentsLoading(false));
    })
}

export const loadMoreComments = ({courseId, chapterId, lessonId}) => (dispatch, getState) => {
  dispatch(setCommentsLoading(true));
  const state = getState();
  const sorting = selectCommentsSorting(state);
  const page = selectCommentsPage(state);

  axios.get(generatePath(apiRoutes.LESSON_COMMENTS, {courseId, chapterId, lessonId, sorting, page: page + 1}))
    .then(({data}) => {
      dispatch(setCommentsPage(page + 1))
      dispatch(addComments(data.data));
    })
    .catch(err => {
      console.log(err);
    })
    .finally(() => {
      dispatch(setCommentsLoading(false));
    })
}

export const sortCommentsByNew = ({courseId, chapterId, lessonId}) => dispatch => {
  dispatch(setCommentsSorting('new'));
  dispatch(loadComments({
    lessonId,
    chapterId,
    courseId,
  }));
}

export const sortCommentsByOld = ({courseId, chapterId, lessonId}) => dispatch => {
  dispatch(setCommentsSorting('old'));
  dispatch(loadComments({
    lessonId,
    chapterId,
    courseId,
  }));
}

export const postComment = ({courseId, chapterId, lessonId, commentData, onSuccess}) => dispatch => {
  dispatch(setCommentsSubmitting(true));
  axios.post(generatePath(apiRoutes.LESSON_POST_COMMENTS, {courseId, chapterId, lessonId}), commentData)
    .then(({data}) => {
      dispatch(addComments([data.data]));
      if(onSuccess){
        onSuccess(data.data);
      }
    })
    .catch(err => {

    })
    .finally(() => {
      dispatch(setCommentsSubmitting(false));
    })
}

const initialState = {
  list: [],
  sorting: 'new',
  page: 1,
  total: 0,
  isLoading: false,
  submitting: false,
}


const commentsSlice = createSlice({
  name: 'comments',
  initialState,
  reducers: {
    setComments: (state, {payload}) => {
      state.list = payload;
      return state;
    },
    clearComments: (state) => {
      state.list = [];
      return state;
    },
    addComments: (state, {payload}) => {

      state.list.push(...payload);

      return state;
    },
    setCommentsSubmitting: (state, {payload}) => {
      state.submitting = payload;

      return state;
    },
    setCommentsLoading: (state, {payload}) => {
      state.isLoading = payload;

      return state;
    },
    setTotalComments: (state, {payload}) => {
      state.total = payload;

      return state;
    },
    setCommentsSorting: (state, {payload}) => {
      state.sorting = payload;

      return state;
    },
    setCommentsPage: (state, {payload}) => {
      state.page = payload || 1;

      return state;
    }
  }
})

export const {
  setComments,
  addComments,
  clearComments,
  setCommentsLoading,
  setTotalComments,
  setCommentsSorting,
  setCommentsPage,
  setCommentsSubmitting,
} = commentsSlice.actions;

export default commentsSlice.reducer;
