import React, {Suspense} from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import COURSE_ROUTES from "../../Course/config/routes";
import {AUTH_ROUTES} from "../../Auth/AuthConfig";
import Error404 from "../../Error/Error404";
import {useSelector} from "react-redux";
import {selectIsLoggedIn} from "../../../redux/slices/authSlice";
import SITE_ROUTES from "../config/routes";
import AccountApp from "../../Account/AccountApp";
import FullPageLoader from "../../../Core/Components/Loaders/FullPageLoader";

const CourseList = React.lazy(() => import('../../CourseList/pages/CourseList'));
const CourseRoutes = React.lazy(() => import('../../Course/Course'));
const AuthApp = React.lazy(() => import('../../Auth/AuthApp'))
const EnrollApp = React.lazy(() => import('../../Enroll/EnrollApp'))

function AppRouter() {
  const isLoggedIn = useSelector(selectIsLoggedIn);

  return (
    <Suspense fallback={<FullPageLoader loadingText="Loading..."/>}>
      <Switch>
        <Route path={AUTH_ROUTES.INDEX} render={() => isLoggedIn ? <Redirect to={SITE_ROUTES.INDEX}/> : <AuthApp/>}/>
        <Route
          exact
          path={COURSE_ROUTES.INDEX}
          component={CourseList}
        />
        <Route path={COURSE_ROUTES.ENROLL} component={EnrollApp}/>
        <Route path={COURSE_ROUTES.COURSE_INDEX} component={CourseRoutes}/>
        <Route path={SITE_ROUTES.ACCOUNT} component={AccountApp}/>

        <Route component={Error404}/>
        {/*<Route exact path={SITE_ROUTES.PROFILE.INDEX} component={Profile}/>*/}
      </Switch>
    </Suspense>
  );
}

export default AppRouter;
