import BasicRequest, {AuthRequest} from "../../api";
import apiRoutes from "../../api/apiRoutes";
import {createSlice} from "@reduxjs/toolkit";
import _ from 'lodash';
import axios from "axios";
import history from "@history";
import {generatePath} from "react-router-dom";
import COURSE_ROUTES from "../../App/Course/config/routes";
import {clearAccessToken, setAccessToken} from "../../Core/Utils/AuthUtils";

export const selectIsLoggedIn = state => !!state.Auth.accessToken;
export const selectIsAdmin = state => !!state.Auth.user.is_admin;
export const selectUser = state => state.Auth.user;
export const selectUserFirstName = state => state.Auth.user.first_name;
export const selectUserLastName = state => state.Auth.user.last_name;
export const selectUserFullName = state => `${state.Auth.user.first_name} ${state.Auth.user.last_name}`;
export const selectCompletedLessons = state => state.Auth.user.completed_lessons || [];
export const selectCourseEnrollmentId = state => state.Auth.enrollmentCourseId;

export const selectIsLoggingIn = state => state.Auth.isLoggingIn;
export const selectIsLoginFailed = state => state.Auth.loginFailed;

export const selectIsRegistering = state => state.Auth.isRegistering;
export const selectIsPasswordRequesting = state => state.Auth.isPasswordRequesting;
export const selectIsPasswordResetting = state => state.Auth.isPasswordResetting;

export const selectIsRegistrationComplete = state => state.Auth.isRegistrationComplete;
export const selectIsRequestPasswordComplete = state => state.Auth.isRequestPasswordComplete;
export const selectIsPasswordResetComplete = state => state.Auth.isPasswordResetComplete;
export const selectRegistrationErrors = state => state.Auth.registrationErrors;

export const selectPasswordResetLinkStatus = state => state.Auth.passwordReset.resetTokenStatus;

export const selectIsUpdatingAccount = state => state.Auth.isUpdatingAccount;
export const selectIsAccountUpdated = state => state.Auth.isAccountUpdated;

export const selectIsUpdatingPassword = state => state.Auth.isUpdatingPassword;
export const selectIsPasswordUpdated = state => state.Auth.isPasswordUpdated;
export const selectIsPasswordWrong = state => state.Auth.isPasswordWrong;

export const checkUser = ({onFailure, onSuccess, final}) => dispatch => {
  const accessToken = localStorage.getItem('accessToken');

  AuthRequest.get(apiRoutes.CHECK_USER)
    .then(({data}) => {
      const {user} = data.data;
      dispatch(setLogin({user, accessToken}))
    })
    .catch(err => {
      if (onFailure) {
        onFailure()
      }
      if (err.response?.status === 401) {
        clearAccessToken();
        dispatch(resetLogin());
      }
    })
    .finally(() => {
      if (final) {
        final()
      }
    })
}

export const doLogin = ({email, password}) => (dispatch, getState) => {
  dispatch(setIsLoggingIn(true))
  dispatch(setLoginFailed(false))

  const state = getState();
  const enrollmentCourseId = selectCourseEnrollmentId(state);

  BasicRequest.post(apiRoutes.LOGIN, {
    email: email,
    password: password,
  })
    .then(({data}) => {
      const {user, accessToken} = data.data;

      setAccessToken(accessToken);
      dispatch(setLogin({accessToken, user}));

      if (enrollmentCourseId) {
        history.push(generatePath(COURSE_ROUTES.ENROLL, {courseId: enrollmentCourseId}));
      }

    })
    .catch(err => {
      if (err.response?.status === 401) {
        dispatch(setLoginFailed(true))
      }
    })
    .finally(() => {
      dispatch(setIsLoggingIn(false))
    })
}
export const doLogout = () => dispatch => {
  clearAccessToken();

  dispatch(resetLogin())
}
export const doRegister = (registrationData) => dispatch => {
  dispatch(setIsRegistering(true));
  dispatch(setRegistrationErrors(null));


  axios.post(apiRoutes.REGISTER, registrationData)
    .then(({data}) => {
      const {user, accessToken} = data.data;
      console.log(data);
      setAccessToken(accessToken);
      dispatch(setIsRegistrationComplete(true));

      setTimeout(() => {
        dispatch(setLogin({accessToken, user}));
      }, 3000)

      dispatch(pushToEnrollmentCourse());

    })
    .catch(err => {
      if (err.response) {
        if (err.response.status === 422) {
          dispatch(setRegistrationErrors(err.response.data.errors))
        }
      }
    })
    .finally(() => {
      dispatch(setIsRegistering(false))
    })

}
export const doRequestPassword = passwordResetData => dispatch => {
  dispatch(setIsPasswordRequesting(true))

  axios.post(apiRoutes.PASSWORD_RESET_REQUEST, passwordResetData)
    .then(() => {
      dispatch(setIsRequestPasswordComplete(true))
    })
    .catch(() => {

    })
    .finally(() => {
      dispatch(setIsPasswordRequesting(false))
    })
}

export const checkPasswordResetToken = ({resetToken}) => dispatch => {
  axios.post(apiRoutes.PASSWORD_RESET_TOKEN, {
    token: resetToken
  })
    .then(() => {
      dispatch(setPasswordResetTokenStatus('valid'));
    })
    .catch(() => {
      dispatch(setPasswordResetTokenStatus('invalid'))
    })
}
export const doResetPassword = resetData => dispatch => {
  dispatch(setIsPasswordResetting(true))

  axios.post(apiRoutes.SET_NEW_PASSWORD, resetData)
    .then(() => {
      dispatch(setIsPasswordResetComplete(true))
    })
    .catch(() => {

    })
    .finally(() => {
      dispatch(setIsPasswordResetting(false))
    })
}

export const updateAccount = ({userData, onSuccess}) => dispatch => {
  dispatch(setUpdatingAccount(true));
  axios.put(apiRoutes.ACCOUNT, userData)
    .then(({data}) => {
      dispatch(setUser(data.data));

      dispatch(setAccountUpdated(true));

      setTimeout(() => {
        dispatch(setAccountUpdated(false));
      }, 2000)

      if (onSuccess) {
        onSuccess(data.data);
      }

    })
    .catch(err => {
      console.log(err)
    })
    .finally(() => {
      dispatch(setUpdatingAccount(false));
    })
}

export const updatePassword = ({data, onSuccess}) => dispatch => {
  dispatch(setUpdatingPassword(true));
  dispatch(setPasswordWrong(false));
  axios.put(apiRoutes.ACCOUNT_PASSWORD, data)
    .then(({data}) => {
      dispatch(setPasswordUpdated(true));
      dispatch(setPasswordWrong(false));

      setTimeout(() => {
        dispatch(setPasswordUpdated(false));
      }, 2000)

      if (onSuccess) {
        onSuccess(data.data);
      }
    })
    .catch(err => {
      console.log(err)
      if (err.response?.status == 400) {
        dispatch(setPasswordWrong(true));
      }
    })
    .finally(() => {
      dispatch(setUpdatingPassword(false));
    })
}

export const pushToEnrollmentCourse = () => (dispatch, getState) => {
  const state = getState();
  const enrollmentCourseId = selectCourseEnrollmentId(state);

  if (enrollmentCourseId) {
    history.push(generatePath(COURSE_ROUTES.ENROLL, {courseId: enrollmentCourseId}));
  }
}


const initialState = {
  accessToken: null,
  user: {
    id: 1,
    first_name: '',
    last_name: '',
    email: null,
    completed_lessons: [],
    enrolled_courses: [],
  },
  isUpdated: false,
  enrollmentCourseId: null,

  isLoggingIn: false,
  isRegistering: false,
  isPasswordResetting: false,
  isPasswordRequesting: false,

  loginFailed: false,
  registrationFailed: false,
  isRegistrationComplete: false,
  isRequestPasswordComplete: false,
  isPasswordResetComplete: false,

  registrationErrors: null,

  passwordReset: {
    isResetting: false,
    resetTokenStatus: 'pending',
  },

  isUpdatingAccount: false,
  isAccountUpdated: false,

  isUpdatingPassword: false,
  isPasswordUpdated: false,
  isPasswordWrong: false,
}

const authSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    setLogin: (state, {payload}) => {
      if (payload.accessToken) {
        state.accessToken = payload.accessToken;
      }

      if (payload.user) {
        state.user = payload.user;
      }

      return state;
    },
    assignAccessToken: (state, {payload}) => {
      state.accessToken = payload;

      return state;
    },
    setUser: (state, {payload}) => {
      state.user = payload;

      return state;
    },

    setUpdatingAccount: (state, {payload}) => {
      state.isUpdatingAccount = payload;

      return state;
    },

    setAccountUpdated: (state, {payload}) => {
      state.isAccountUpdated = payload;

      return state;
    },

    setUpdatingPassword: (state, {payload}) => {
      state.isUpdatingPassword = payload;

      return state;
    },

    setPasswordUpdated: (state, {payload}) => {
      state.isPasswordUpdated = payload;

      return state;
    },

    setPasswordWrong: (state, {payload}) => {
      state.isPasswordWrong = payload;

      return state;
    },


    resetLogin: (state) => {
      state = initialState;

      return state;
    },

    setIsLoggingIn: (state, {payload}) => {
      state.isLoggingIn = payload;

      return state;
    },
    setIsRegistering: (state, {payload}) => {
      state.isRegistering = payload;

      return state;
    },
    setIsPasswordRequesting: (state, {payload}) => {
      state.isPasswordRequesting = payload

      return state;
    },
    setIsPasswordResetting: (state, {payload}) => {
      state.isPasswordResetting = payload

      return state;
    },

    setLessonComplete: (state, {payload: {lessonId}}) => {
      state.user.completed_lessons = _.union(state.user.completed_lessons, [lessonId]);

      return state;
    },

    setLessonIncomplete: (state, {payload: {lessonId}}) => {
      state.user.completed_lessons = _.filter(state.user.completed_lessons, _lessonId => _lessonId !== lessonId);

      return state;
    },

    setCourseEnrollment: (state, {payload: courseId}) => {
      state.enrollmentCourseId = courseId;

      return state;
    },
    setLoginFailed: (state, {payload}) => {
      state.loginFailed = payload;

      return state;
    },
    setRegistrationErrors: (state, {payload}) => {
      state.registrationErrors = payload;
    },
    setPasswordResetTokenStatus: (state, {payload}) => {
      state.passwordReset.resetTokenStatus = payload;

      return state;
    },

    setIsRegistrationComplete: (state, {payload}) => {
      state.isRegistrationComplete = payload;
    },
    setIsRequestPasswordComplete: (state, {payload}) => {
      state.isRequestPasswordComplete = payload;

      return state;
    },
    setIsPasswordResetComplete: (state, {payload}) => {
      state.isPasswordResetComplete = payload;

      return state;
    }
  }
})

const {
  setUser,
  assignAccessToken,
  setUpdatingAccount,
  setAccountUpdated,
  setPasswordUpdated,
  setUpdatingPassword,
  setPasswordWrong
} = authSlice.actions


export const {
  setIsLoggingIn,
  setIsRegistering,
  setIsPasswordRequesting,
  setIsPasswordResetting,

  setLessonIncomplete,
  setLessonComplete,

  setLogin,
  resetLogin,

  setCourseEnrollment,
  setLoginFailed,
  setRegistrationErrors,

  setIsRegistrationComplete,
  setIsRequestPasswordComplete,
  setPasswordResetTokenStatus,
  setIsPasswordResetComplete,
} = authSlice.actions;

export default authSlice.reducer;