import React from 'react';
import Loader from "react-spinners/ClipLoader";
import PropTypes from 'prop-types';

function FullPageLoader(props) {
  return (
    <div className="is-flex is-justify-content-center is-align-items-center" style={{height: '100%', minHeight: 300}}>
      <div className="is-flex is-align-items-center is-flex-direction-column">
        <div>
          <Loader size={50}/>
        </div>
        <div>
          {props.loadingText}
        </div>
      </div>
    </div>
  );
}
FullPageLoader.propTypes = {
  loadingText: PropTypes.string.isRequired
}

export default FullPageLoader;