import React from 'react';
import './App.scss';
import {Router} from "react-router-dom";
import AppRouter from './App/shared/router/AppRouter';
import UserInfoUpdater from "./App/shared/UserInfoUpdater";
import SiteKeyProvider from "./App/shared/Providers/SiteKeyProvider";
import Layout from "./layout/Layout";
import ScrollProvider from "./App/shared/Providers/ScrollProvider";
import {store} from "./redux/store";
import history from '@history';
import {Provider} from "react-redux";
import {ModalProvider} from "react-modal-hook";

function App(props) {
  return (
    <>
      <Provider store={store}>
        <SiteKeyProvider>
          <UserInfoUpdater>
            {/*<ResponsiveProvider>*/}
            <Router history={history}>
              <ModalProvider>
                <ScrollProvider>
                  <Layout>
                    <AppRouter history={history}/>
                  </Layout>
                </ScrollProvider>
              </ModalProvider>
            </Router>
            {/*</ResponsiveProvider>*/}
          </UserInfoUpdater>
        </SiteKeyProvider>
      </Provider>
    </>
  );
}

export default App;
