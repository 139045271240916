import axios from "axios";

const apiRoutes = {
  BASE_URL: process.env.REACT_APP_API_BASE_URL,
  // BASE_URL: 'http://localhost:8000',
  // BASE_URL: 'http://api.lcg.test',
  CHECK_USER: '/check-user',

  LOGIN: '/login',
  REGISTER: '/register',
  PASSWORD_RESET_REQUEST: '/password/request',
  PASSWORD_RESET_TOKEN: '/password/verify',
  SET_NEW_PASSWORD: '/password/reset',
  LOGOUT: '/logout',

  ACCOUNT: '/account',
  ACCOUNT_PASSWORD: '/account/password',

  ENROLL_COURSE: '/enroll/course/:courseId',
  ENROLL_SETUP_INTENT: '/enroll/intent',
  CHECK_COUPON: '/enroll/coupon/check',

  RESOLVE_SITE_KEY: 'site-key',
  VERIFY_SITE_KEY: 'verify-site-key/:siteKey',

  RESOURCES: '/courses/:courseId/resources',
  RESOURCE_CATEGORY: '/courses/:courseId/resources/:resourceId',

  COURSE_GET_LIST: '/courses',
  COURSE_GET_CURRICULUM: '/courses/:courseId/curriculum',

  LESSON: '/courses/:courseId/chapters/:chapterId/lessons/:lessonId',
  LESSON_COMMENTS: '/courses/:courseId/chapters/:chapterId/lessons/:lessonId/comments?page=:page&sort_by=:sorting',
  LESSON_POST_COMMENTS: '/courses/:courseId/chapters/:chapterId/lessons/:lessonId/comments',
  LESSON_RESOURCES: '/courses/:courseId/chapters/:chapterId/lessons/:lessonId/resources',

  LESSON_COMPLETE: '/courses/:courseId/chapters/:chapterId/lessons/:lessonId/complete',
  LESSON_INCOMPLETE: '/courses/:courseId/chapters/:chapterId/lessons/:lessonId/incomplete',

}
axios.defaults.baseURL = apiRoutes.BASE_URL;

export default apiRoutes;
