

const SITE_ROUTES = {
    INDEX: '/',
    AUTH: '/auth',
    ACCOUNT: '/account',
    ACCOUNT_PROFILE: '/account/profile',
    ACCOUNT_PASSWORD: '/account/password',
}

export default SITE_ROUTES;
