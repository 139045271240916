import React, {useEffect, useState} from 'react';
import style from "./styles/account.module.scss";
import ValidatedTextArea from "../../Core/Components/Form/ValidatedTextArea";
import Formsy from "formsy-react";
import useForm from "../../Core/Components/Hooks/useForm";
import {useDispatch, useSelector} from "react-redux";
import {selectIsAccountUpdated, selectIsUpdatingAccount, selectUser, updateAccount} from "../../redux/slices/authSlice";
import ValidatedInput from "../../Core/Components/Form/ValidatedInput";
import _ from 'lodash';

function AccountProfile(props) {

  const [isValid, setValid] = useState(false)
  const dispatch = useDispatch();

  const userInfo = useSelector(selectUser);
  const isUpdatingAccount = useSelector(selectIsUpdatingAccount);
  const isAccountUpdated = useSelector(selectIsAccountUpdated);

  const [isChanged, setIsChanged] = useState(false);

  const {setForm, form, setInForm} = useForm({})

  useEffect(() => {
    setForm(_.pick(userInfo, [
      'first_name',
      'last_name',
      'display_name',
      'bio',
      'address',
      'city',
      'state',
      'country',
    ]))
  }, [userInfo])


  const submitForm = () => {
    dispatch(
      updateAccount({
        userData: form,
        onSuccess: () => {
          setIsChanged(false);
        }
      })
    )
  }

  const changeData = (name, value) => {
    setIsChanged(true);
    setInForm(name, value);
  }

  return (
    <div className={style.settingWrapper}>
      <Formsy
        onSubmit={submitForm}
        onValid={() => setValid(true)}
        onInvalid={() => setValid(false)}
      >
        <h3 className={style.settingGroupHeading}>PROFILE INFORMATION</h3>
        <div className="columns is-multiline">
          <div className="column is-half-tablet">
            <ValidatedInput
              label="First Name"
              name="first_name"
              value={form.first_name}
              required
              validationError="Please enter your first name"
              onChange={value => changeData('first_name', value)}
            />
          </div>
          <div className="column is-half-tablet">
            <ValidatedInput
              label="Last Name"
              name="last_name"
              value={form.last_name}
              required
              validationError="Please enter your last name"
              onChange={value => changeData('last_name', value)}
            />
          </div>
          <div className="column is-full">
            <ValidatedInput
              label="Display Name"
              name="display_name"
              value={form.display_name}
              onChange={value => changeData('display_name', value)}
            />
          </div>
          <div className="column is-full">
            <ValidatedTextArea
              label="Profile Bio"
              name="bio"
              value={form.bio}
              onChange={value => changeData('bio', value)}
            />
          </div>
        </div>
        <h3 className={style.settingGroupHeading}>ADDRESS</h3>
        <div className="columns is-multiline">
          <div className="column is-full">
            <ValidatedInput
              label="Address"
              name="address"
              value={form.address}
              onChange={value => changeData('address', value)}
            />
          </div>
          <div className="column is-half-tablet">
            <ValidatedInput
              label="City"
              name="city"
              value={form.city}
              onChange={value => changeData('city', value)}
            />
          </div>
          <div className="column is-half-tablet">
            <ValidatedInput
              label="State"
              name="state"
              value={form.state}
              onChange={value => changeData('state', value)}
            />
          </div>
          <div className="column is-half-tablet">
            <ValidatedInput
              label="Country"
              name="country"
              value={form.country}
              onChange={value => changeData('country', value)}
            />
          </div>
        </div>
        <div className="mt-3">
          <div className="is-flex is-justify-content-center">
            <button
              className={style.submitButton}
              disabled={!isValid || !isChanged || isUpdatingAccount}
              type="submit"
            >
              {
                isAccountUpdated ? "SAVED" :
                  (
                    isUpdatingAccount ? "SAVING" : "SAVE"
                  )
              }
            </button>
          </div>
        </div>
      </Formsy>
    </div>
  );
}

export default AccountProfile;