import {createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import apiRoutes from "../../api/apiRoutes";
import {generatePath} from "react-router-dom";
import _ from "lodash";

// Selectors
export const selectAllResources = state => state.Resources.list;
export const selectResourceBySlug = ({resourceSlug}) => state => _.find(state.Resources.list, resource => resource.slug === resourceSlug);
export const selectResourcesByParentId = ({resourceParentId}) => state => _.filter(state.Resources.list, resource => resource.parent_id === resourceParentId);
export const selectResourceCategories = state => _.filter(state.Resources.list, resource => resource.parent_id === 0);
export const selectIsLoadingResources = state => state.Resources.isLoading;

export const loadResourceCategories = ({courseId}) => dispatch => {
  dispatch(setResourcesLoading(true))
  axios.get(generatePath(apiRoutes.RESOURCES, {courseId}))
    .then(({data}) => {
      dispatch(addResources(data.data));
    })
    .catch(err => {
      console.log(err)
    })
    .finally(() => {
      dispatch(setResourcesLoading(false));
    })
}
export const loadResourcesById = ({courseId, resourceId}) => dispatch => {
  dispatch(setResourcesLoading(true))
  axios.get(generatePath(apiRoutes.RESOURCE_CATEGORY, {courseId, resourceId}))
    .then(({data}) => {

      const resources = data.data;
      const children = [
        ...resources.children
      ]
      delete resources.children;

      dispatch(addResources(resources));
      dispatch(addResources(children));
    })
    .catch(err => {
      console.log(err)
    })
    .finally(() => {
      dispatch(setResourcesLoading(false));
    })
}


const initialState = {
  list: [],
  isLoading: false,
}

const resourceSlice = createSlice({
  name: 'resources',
  initialState,
  reducers: {
    setResources: (state, {payload}) => {

    },

    setResourcesLoading: (state, {payload}) => {
      state.isLoading = payload;
      return state;
    },

    addResources: (state, {payload: newResource}) => {
      if(Array.isArray(newResource)) {
        newResource.forEach(resource => {
          const exists = _.findIndex(state.list, _resource => _resource.id === resource.id ) !== -1;
          if(exists){
            state.list = state.list.map(_resource => {
              if(_resource.id === resource.id){
                return resource;
              }

              return _resource;
            })
          }
          else{
            state.list.push(resource);
          }
        })
      }
      else{
        let exists = false;
        state.list = state.list.map(resource => {
          if(resource.id === newResource.id){
            exists = true;
            return newResource;
          }
          return resource;
        })

        if(!exists){
          state.list.push(newResource);
        }
      }
      _.sortBy(state.list, ['position']);

      return state;
    },
  }
})

export const {addResources, setResources, setResourcesLoading} = resourceSlice.actions;

export default resourceSlice.reducer;