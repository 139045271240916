import Axios from "axios";
import apiRoutes from "./apiRoutes";


const BasicRequest = Axios.create({
  baseURL: apiRoutes.BASE_URL,
});

// const UNAUTHORIZED = 401;

export const AuthRequest = Axios.create({
  baseURL: apiRoutes.BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Authorization': '',
  },
})

AuthRequest.interceptors.response.use(
  response => response,
  error => {

    // console.log('error ', error);
    // if (!error.response.status) {
    //   store.dispatch(logout());
    //   return Promise.reject();
    // }
    // const state = store.getState();
    // const accessToken = state.Auth.get('accessToken');

    // const {status} = error.response;

    // if (status === UNAUTHORIZED && accessToken !== null) {
    //   store.dispatch(doLogout());
    // }
    return Promise.reject(error);
  }
)


export default BasicRequest;
