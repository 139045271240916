import React from 'react';
import {Link, useLocation} from "react-router-dom";
import SITE_ROUTES from "../App/shared/config/routes";
import {useDispatch, useSelector} from "react-redux";
import {selectSiteName} from "../redux/slices/siteSlice";
import {doLogout, selectIsLoggedIn} from "../redux/slices/authSlice";
import {AUTH_ROUTES} from "../App/Auth/AuthConfig";
import {closeMobileNav, openMobileNav, selectMobileCourseNavOpen} from "../redux/slices/courseNavSlice";

function LayoutHeader(props) {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const dispatch = useDispatch();
  const {pathname} = useLocation();

  const siteName = useSelector(selectSiteName);

  const mobileNavOpen = useSelector(selectMobileCourseNavOpen)

  return (
     <div className="topbar">
       <div className="courseName"><Link to={SITE_ROUTES.INDEX}>{siteName}</Link></div>

       <div className="topbar-nav">
         <ul className="menu">
           {
             isLoggedIn ?
                <>
                  <li className="menu-item menu-dsk">
                    <Link
                       to={SITE_ROUTES.ACCOUNT}
                       className={pathname.startsWith(SITE_ROUTES.ACCOUNT) && "active"}
                    >
                      MY ACCOUNT
                    </Link>
                  </li>
                  <li className="menu-item menu-dsk">
                    <a
                       href="#logout"
                       onClick={e => {
                         e.preventDefault();
                         dispatch(doLogout())
                       }}
                    >
                      LOGOUT
                    </a>
                  </li>
                </>

                :

                <li className="menu-item menu-dsk">
                  <Link to={AUTH_ROUTES.LOGIN}>LOGIN</Link>
                </li>
           }

         </ul>
         <button className={'mobileNavBtn'}
                 onClick={mobileNavOpen ? () => dispatch(closeMobileNav()) : () => dispatch(openMobileNav())}>
           <span className="top"/>
           <span className="middle"/>
           <span className="bottom"/>
         </button>
       </div>
     </div>
  );
}

export default LayoutHeader;