const COURSE_ROUTES = {
    INDEX: '/',
    INTRO: '/courses/:courseId',

    ENROLL: '/enroll/:courseId/:priceId([0-9]+)?',
    ENROLL_REGISTER: '/enroll/:courseId/:priceId([0-9]+)?/register',
    ENROLL_LOGIN: '/enroll/:courseId/:priceId([0-9]+)?/login',
    ENROLL_FORGOT_PASSWORD: '/enroll/:courseId/:priceId([0-9]+)?/password',
    ENROLL_CHECKOUT: '/enroll/:courseId/:priceId([0-9]+)?/checkout',
    ENROLL_SUCCESS: '/enroll/:courseId/success',
    ENROLL_ERROR: '/enroll/:courseId/rejected',

    COURSE_INDEX: '/courses/:courseId',
    RESOURCES: '/courses/:courseId/resources',
    RESOURCE: '/courses/:courseId/resources/:resourceId',

    COMMUNITY: '/courses/:courseId/community',

    CHAPTER: '/courses/:courseId/content/:chapterId',
    LESSON: '/courses/:courseId/content/:chapterId/:lessonId',

}

export default COURSE_ROUTES;
