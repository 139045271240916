import React from 'react';
import {withFormsy} from "formsy-react";
import _ from 'lodash';

class ValidatedInput extends React.Component {

  changeValue = e => {
    this.props.setValue(e.currentTarget.value);

    if (this.props.onChange) {
      this.props.onChange(e.currentTarget.value);
    }
  }

  render() {
    const {errorMessage, isPristine} = this.props;

    const props = _.pick(this.props, [
      'type',
      'id',
      'placeholder',
    ])

    return (
      <div className="field">

        {
          this.props.label &&
          <label htmlFor={props.id} className="label">{this.props.label}</label>
        }

        <div className="control">
          <input
            className={`input ${Boolean(errorMessage) && !isPristine ? 'is-danger' : ''}`}
            {...props}
            value={this.props.value || ''}

            onChange={this.changeValue}
          />
        </div>
        {
          errorMessage && !isPristine &&
          <div className="help is-danger">{errorMessage}</div>
        }
      </div>
    );
  }

}

export default withFormsy(ValidatedInput);