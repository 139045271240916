import authReducer from './slices/authSlice';
import coreReducer from './slices/coreSlice';
import courseReducer from './slices/courseSlice';
import siteReducer from './slices/siteSlice';
import resourceReducer from './slices/resourceSlice';
import commentsReducer from './slices/commentsSlice';
import enrollmentSlice from "./slices/enrollmentSlice";
import courseTourSlice from "./slices/courseTourSlice";
import courseNavReducer from "./slices/courseNavSlice";
import videoOverlaySlice from "./slices/videoOverlaySlice";

const reducers = {
  CourseNav: courseNavReducer,
  Auth: authReducer,
  Core: coreReducer,
  Courses: courseReducer,
  CourseTour: courseTourSlice,
  Site: siteReducer,
  Resources: resourceReducer,
  Comments: commentsReducer,
  Enrollment: enrollmentSlice,
  VideoOverlay: videoOverlaySlice,
}

export default reducers;
