import {useEffect} from 'react';
import {useLocation} from "react-router-dom";

function ScrollProvider(props) {

  const {pathname} = useLocation();

  useEffect(() => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }, [pathname])

  return props.children;
}

export default ScrollProvider;