import {combineReducers} from 'redux';

import reducers from './reducers';
import {configureStore} from "@reduxjs/toolkit";

const middlewares = [];

// export const store = createStore(
//   combineReducers({
//     ...reducers,
//   }),
//   composeEnhancers(applyMiddleware(...middlewares))
// );

export const store = configureStore({
  reducer: combineReducers({
    ...reducers
  }),
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: {
        ignoredActions: []
      }
    }).concat(middlewares),
  devTools: process.env.NODE_ENV === 'development'
})

export {default as history} from '@history'