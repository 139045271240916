import React from 'react';
import style from './error.module.scss';
import {Link} from "react-router-dom";
import SITE_ROUTES from "../shared/config/routes";

function Error404(props) {
  return (
    <div className="container has-text-centered">
      <div className={style.error}>
        <div className="title is-1">404</div>
        <div className="subtitle is-4">Not found</div>
        <div className="">
          The page you tried to access not exist.&nbsp;
          <Link className="has-text-weight-bold" to={SITE_ROUTES.INDEX}>Click here</Link> to return to home.
        </div>
      </div>
    </div>
  );
}

export default Error404;