import axios from "axios";
import BasicRequest, {AuthRequest} from "../../api";

export const clearAccessToken = () => {
  localStorage.removeItem('accessToken');

  AuthRequest.defaults.headers.Authorization = undefined;
  axios.defaults.headers.Authorization = undefined;
  BasicRequest.defaults.headers.Authorization = undefined;
}

export const setAccessToken = accessToken => {
  localStorage.setItem('accessToken', accessToken);

  AuthRequest.defaults.headers.Authorization = `Bearer ${accessToken}`;
  axios.defaults.headers.Authorization = `Bearer ${accessToken}`;
}

export const setSiteIdentifier = identifier => {
  AuthRequest.defaults.headers['X-Site-Key'] = identifier;
  BasicRequest.defaults.headers['X-Site-Key'] = identifier;
  axios.defaults.headers['X-Site-Key'] = identifier;
}