import {createSlice} from "@reduxjs/toolkit";


export const selectSiteInfo = state => state.Site.info;
export const selectSiteName = state => state.Site.info.name;
export const selectSiteLoading = state => state.Site.isLoading;
export const selectSiteSupportEmail = state => state.Site.support_email;
export const selectSiteReplyEmail = state => state.Site.reply_email;


// Terms
export const selectSiteLabelsLessonsSingular = state => state.Site.info?.labels?.course_lessons_singular;
export const selectSiteLabelsLessonsPlural = state => state.Site.info?.labels?.course_lessons_plural;
export const selectSiteLabelsChaptersSingular = state => state.Site.info?.labels?.course_chapters_singular;
export const selectSiteLabelsChaptersPlural = state => state.Site.info?.labels?.course_chapters_plural;
export const selectSiteLabelsResourcesSingular = state => state.Site.info?.labels?.resources_singular;
export const selectSiteLabelsResourcesPlural = state => state.Site.info?.labels?.resources_plural;
export const selectSiteLabelsLiveSessionsSingular = state => state.Site.info?.labels?.live_sessions_singular;
export const selectSiteLabelsLiveSessionsPlural = state => state.Site.info?.labels?.live_sessions_plural;
export const selectSiteLabelsPageCommunity = state => state.Site.info?.labels?.page_community;
export const selectSiteLabelsPageResources = state => state.Site.info?.labels?.page_resources;
export const selectSiteLabelsPageLiveSessions = state => state.Site.info?.labels?.page_live_sessions;
export const selectSiteLabelsPageIntro = state => state.Site.info?.labels?.page_intro;
export const selectSiteStripeIntegration = state => state.Site.info.integrations.stripe

const initialState = {
  info: {
    name: 'Test Site',
    domain: '',
    support_email: '',
    reply_email: '',
    integrations: {

    }
  },
  isLoading: false,
}

const siteSlice = createSlice({
  name: 'site',
  initialState,
  reducers: {
    setSiteInfo: (state, {payload}) => {
      state.info = payload;
      return state;
    }
  }
})


export const {setSiteInfo} = siteSlice.actions;

export default siteSlice.reducer