import React, {useEffect, useState} from 'react';
import style from "./styles/account.module.scss";
import Formsy from "formsy-react";
import ValidatedInput from "../../Core/Components/Form/ValidatedInput";
import {
  selectIsAccountUpdated, selectIsPasswordUpdated, selectIsPasswordWrong,
  selectIsUpdatingAccount,
  selectIsUpdatingPassword,
  selectUser,
  updateAccount, updatePassword
} from "../../redux/slices/authSlice";
import {useDispatch, useSelector} from "react-redux";
import useForm from "../../Core/Components/Hooks/useForm";
import _ from "lodash";

function AccountPassword() {
  const [isValid, setValid] = useState(false)
  const dispatch = useDispatch();

  const userInfo = useSelector(selectUser);
  const isUpdatingPassword = useSelector(selectIsUpdatingPassword);
  const isPasswordUpdated = useSelector(selectIsPasswordUpdated);
  const isPasswordWrong = useSelector(selectIsPasswordWrong);

  const [isChanged, setIsChanged] = useState(false);

  const {setForm, form, setInForm, resetForm} = useForm({})

  useEffect(() => {
    setForm(_.pick(userInfo, [
      'password',
      'password_confirm',
    ]))
  }, [userInfo])

  const submitForm = () => {
    dispatch(
      updatePassword({
        data: {
          password: form.password,
          new_password: form.new_password,
        },
        onSuccess: () => {
          setIsChanged(false);
          resetForm()
        }
      })
    )
  }

  const changeData = (name, value) => {
    setIsChanged(true);
    setInForm(name, value);
  }

  return (
    <div className={style.settingWrapper}>
      {
        isPasswordUpdated ?

          "Your password has been updated. You can use the new password to log in next time."
          :
          <Formsy
            onSubmit={submitForm}
            onValid={() => setValid(true)}
            onInvalid={() => setValid(false)}
          >
            <h3 className={style.settingGroupHeading}>CHANGE PASSWORD</h3>
            {
              isPasswordWrong && (
                <div className="mt-3 has-text-danger">
                  The password you entered is incorrect.
                </div>
              )
            }
            <div className="columns is-multiline">
              <div className="column is-half-tablet">
                <ValidatedInput
                  label="New Password"
                  name="new_password"
                  type="password"
                  value={form.new_password}
                  required
                  validationError="Please enter a password"
                  validations={{
                    minLength: 8
                  }}
                  validationErrors={{
                    minLength: "Password must be at least 8 characters long"
                  }}
                  onChange={value => changeData('new_password', value)}
                />
              </div>
              <div className="column is-half-tablet">
                <ValidatedInput
                  label="Confirm Password"
                  name="password_confirm"
                  type="password"
                  value={form.password_confirm}
                  required
                  validationError="Please confirm your password"
                  validations={{
                    equalsField: 'new_password'
                  }}
                  validationErrors={{
                    equalsField: "Password does not match"
                  }}
                  onChange={value => changeData('password_confirm', value)}
                />
              </div>
              <div className="column is-full">
                <ValidatedInput
                  label="Current Password"
                  name="password"
                  type="password"
                  value={form.password}
                  required
                  validationError="Please enter your current password"
                  onChange={value => changeData('password', value)}
                />
              </div>
            </div>

            <div className="mt-3">
              <div className="is-flex is-justify-content-center">
                <button
                  className={style.submitButton}
                  disabled={!isValid || !isChanged || isUpdatingPassword}
                  type="submit"
                >
                  {
                    isUpdatingPassword ? "SAVING" : "SAVE"
                  }
                </button>
              </div>
            </div>
          </Formsy>
      }
    </div>
  );
}

export default AccountPassword;